<template>
  <div class='page-box' ref='page'>
    <BaseSubTitle title="物流模版"/>
    <div>
      <a-button v-pms="'add'" type='primary' icon='plus' @click='addTemplate'>新增模板</a-button>
    </div>
    <a-spin :spinning='tableLoading' v-if="templateList.length>0">
      <a-collapse  expand-icon-position="right" class='collapse'>
        <a-collapse-panel v-for='(item,index) in templateList' :key='item.shopLogisticsId'>
          <div slot="header" class='collapse-header'>
            <div class='collapse-title'>
              {{item.name}}<span v-if='item.productUseNum>0'>（已被{{item.productUseNum}}个商品使用）</span>
            </div>
            <div class='collapse-header-right'>
              <p>最后编辑时间：{{item.updateTime}}</p>
              <a-button type='link' :disabled='item.productUseNum<=0' @click.stop="viewGoodsList(item.shopLogisticsId)">查看商品</a-button>
              <a-button type='link' :loading='shopLogisticsId === item.shopLogisticsId+"copy"' @click.stop='copyTemplate(item.shopLogisticsId)'>复制模版</a-button>
              <a-button type='link' v-pms="'edit'" @click.stop='editTemplate(item.shopLogisticsId)'>修改</a-button>
              <a-popconfirm
                :disabled='item.productUseNum>0'
                v-pms="'delete'"
                placement="topRight"
                title="确定要删除？"
                ok-text="确定"
                cancel-text="取消"
                @confirm="()=>confirmDeleteTemplate(item.shopLogisticsId,index)"
              >
                <a-button type="link" :style="{color:item.productUseNum>0?'#999':'#1890ff',cursor:item.productUseNum>0?'no-drop':'pointer'}" :loading='shopLogisticsId === item.shopLogisticsId+"delete"' @click.stop>删除</a-button>
              </a-popconfirm>
            </div>
          </div>
          <a-table bordered v-if='item.children.length' :pagination='false' :columns="item.calcMethod === 1 ? columns1:columns2" :data-source="item.children" :rowKey="(record,index) => index">
          </a-table>
        </a-collapse-panel>
      </a-collapse>
      <a-pagination v-if='total>0' :pageSize="pageSize" style='text-align:right;' show-quick-jumper :default-current="current" :total="total" @change="onChangePage" />
    </a-spin>
    <no-data text='暂无物流模板' v-else></no-data>
    <a-modal v-model="goodsModalShow" :footer='null' title="商品列表" :getContainer="()=>$refs.page">
      <a-table bordered :rowKey="record => record.productId" :pagination='pagination' :columns="goodsLostColumns" :data-source="goodsList" @change="changeGoodsListPage">
        <template  slot="name" slot-scope="text, record">
          <div class="goods-info">
            <base-img
              class="goods-img"
              :src="record.productImg"
              width="60"
              height="60"
            />
            <p class="goods-name el2">{{record.name}}</p>
          </div>
        </template>
        <div class="table-operations" slot="operation" slot-scope="text, record">
          <a-button type='link' @click='goGoodDetail(record.productId)'>查看详情</a-button>
        </div>
      </a-table>
    </a-modal>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, onUnmounted } from '@vue/composition-api'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
import { logistics } from '@/api'
export default {
  name: 'PageTemplate',
  setup (props, { root }) {
    const columns1 = [
      {
        title: '可配送区域',
        dataIndex: 'regionName',
        scopedSlots: { customRender: 'regionName' }
      },
      {
        title: '首件（个）',
        dataIndex: 'firstNum',
        width: '13%',
        scopedSlots: { customRender: 'firstNum' }
      },
      {
        title: '运费（元）',
        dataIndex: 'firstPrice',
        width: '13%',
        scopedSlots: { customRender: 'firstPrice' }
      },
      {
        title: '续件（个）',
        dataIndex: 'addNum',
        width: '13%',
        scopedSlots: { customRender: 'addNum' }
      },
      {
        title: '续费（元）',
        dataIndex: 'addPrice',
        width: '13%',
        scopedSlots: { customRender: 'addPrice' }
      }
    ]
    const columns2 = [
      {
        title: '可配送区域',
        dataIndex: 'regionName',
        scopedSlots: { customRender: 'regionName' }
      },
      {
        title: '首重（kg）',
        dataIndex: 'firstNum',
        width: '13%',
        scopedSlots: { customRender: 'firstNum' }
      },
      {
        title: '运费（元）',
        dataIndex: 'firstPrice',
        width: '13%',
        scopedSlots: { customRender: 'firstPrice' }
      },
      {
        title: '续重（kg）',
        dataIndex: 'addNum',
        width: '13%',
        scopedSlots: { customRender: 'addNum' }
      },
      {
        title: '续费（元）',
        dataIndex: 'addPrice',
        width: '13%',
        scopedSlots: { customRender: 'addPrice' }
      }
    ]
    const goodsLostColumns = [
      {
        title: '可配送区域',
        dataIndex: 'name',
        scopedSlots: { customRender: 'name' }
      },
      {
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' }
      }
    ]

    const state = reactive({
      shopLogisticsId: '',
      tableLoading: false,
      goodsModalLoading: false,
      goodsModalShow: false,
      current: 1,
      total: 10,
      pageSize: 10,
      templateList: [],
      goodsList: [],
      pagination: {
        current: 1,
        pageSize: 5,
        total: 0,
        size: 'small'
      }
    })
    function editTemplate (id) {
      root.$router.push('/shop/logistics/edit/' + id)
    }
    function addTemplate () {
      root.$router.push('/shop/logistics/add')
    }

    function onChangePage (pageNumber) {
      if (pageNumber !== state.current) {
        state.current = pageNumber
        getTemplatePage()
      }
    }
    async function getTemplatePage () {
      state.tableLoading = true
      let { code, msg, data, page } = await logistics.getTemplatePage({
        shopId: getSession(SHOPID),
        current: state.current,
        size: state.size
      })
      state.tableLoading = false
      if (code === '00000') {
        state.templateList = data
        state.current = page.pageNo
        state.size = page.pageSize.toString()
        state.total = page.total
      } else {
        root.$message.error(msg || '网络错误，请重试')
      }
    }

    async function confirmDeleteTemplate (id, index) {
      state.shopLogisticsId = id + 'delete'
      let { code, msg } = await logistics.deleteTemplate({ shopLogisticsId: id })
      state.shopLogisticsId = ''
      if (code === '00000') {
        root.$message.success('删除成功')
        state.templateList.splice(index, 1)
      } else {
        root.$message.error(msg || '网络错误，请重试')
      }
    }
    async function copyTemplate (id) {
      state.shopLogisticsId = id + 'copy'
      let { code, msg } = await logistics.copyTemplate({
        shopLogisticsId: id
      })
      state.shopLogisticsId = ''
      if (code === '00000') {
        root.$message.success('复制成功')
        getTemplatePage()
      } else {
        root.$message.error(msg || '复制失败')
      }
    }
    const callback = () => { getTemplatePage() }
    onMounted(async () => {
      getTemplatePage()
      root.$bus.$on('PageLogisticsList:refresh', callback)
    })
    onUnmounted(() => {
      root.$bus.$off('PageLogisticsList:refresh', callback)
    })
    async function viewGoodsList (id) {
      state.shopLogisticsId = id
      state.goodsModalShow = true
      getGoodsList()
    }
    async function getGoodsList () {
      state.goodsModalLoading = true
      let { code, msg, data, page } = await logistics.getGoodsList({
        shopLogisticsId: state.shopLogisticsId,
        shopId: getSession(SHOPID),
        current: state.pagination.current,
        size: state.pagination.pageSize
      })
      state.goodsModalLoading = false
      if (code === '00000') {
        state.goodsList = data
        state.pagination.current = page.pageNo
        state.pagination.total = page.total
        state.pagination.pageSize = page.pageSize
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function changeGoodsListPage (e) {
      state.pagination = e
      getGoodsList()
    }
    function goGoodDetail (productId) {
      root.$router.push(`/goods/goods_detail/${productId}`)
    }
    return {
      columns1,
      columns2,
      goodsLostColumns,
      ...toRefs(state),
      onChangePage,
      getTemplatePage,
      confirmDeleteTemplate,
      addTemplate,
      editTemplate,
      copyTemplate,
      viewGoodsList,
      changeGoodsListPage,
      getGoodsList,
      goGoodDetail
    }
  }
}
</script>
<style lang="less" scoped>
  ::v-deep .ant-collapse-content-box {
    padding: 0;
  }
  ::v-deep .ant-select-dropdown {
    max-height: 800px;
  }
  .collapse {
    margin: 24px 0;
    ::v-deep .ant-table-thead > tr > th {
      background-color: #fff;
    }
    .collapse-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .collapse-title {
        word-break: break-all;
        font-size: 16px;
        font-weight: 900;
        span {
          font-size: 14px;
          color: #666;
          font-weight: normal;
        }
      }
      .collapse-header-right {
        margin-left: 14px;
        flex-shrink: 0;
        font-size: 14px;
        color: #666;
        display: flex;
        align-items: center;
        ::v-deep .ant-btn-link {
          padding-right: 0;
        }
      }
    }
  }
  .goods-info {
  display: flex;
  align-items: center;
  .goods-img {
    margin-right: 12px;
  }
  .goods-name {
    flex: none;
    max-width: 126px;
    font-size: 14px;
    line-height: 22px;
  }
}
::v-deep .ant-modal-body {
  padding:0;
}
</style>
